import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'

let useError

// create an axios instance
const service = axios.create({
  baseURL: getEnv()[1],
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
  params: {},
  data: {}
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      config.params = config.params || {}
      config.params['token'] = getToken()
      // config.params['page'] = 1
      // config.params['count'] = 1
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (!res.code) {
      return res
    } else {
      if (!useError) {
        if (res.code === 401) {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        }
        Message({
          message: res.errorMsg || '遇到错误',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(res)
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: '网络错误',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

function post({ url, params = {}, data = {}, type = 'post'}, handleError) {
  useError = handleError
  return new Promise((resolve, reject) => {
    service({ url, params, data, method: type }).then(res => {
      resolve(res)
    }).catch(err => {
      if (handleError) {
        reject(err)
      }
    })
  })
}

export default post
