<template>
  <div class="a-container">
    <sub-title title="新建大学" />
    <div class="margin-top-30">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <el-form-item label="大学名称" prop="name">
          <el-input
            ref="name"
            v-model="form.name"
            type="text"
            maxlength="10"
            show-word-limit
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="大学头像" prop="logo">
          <el-upload
            ref="logo"
            v-model="form.logo"
            class="upload-demo"
            :action="uploadUrl"
            :data="dataObj"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :on-change="handleChangePic"
            :file-list="fileList"
            :before-upload="beforeUploadFile"
            :show-file-list="false"
            list-type="picture"
            drag
          >
            <div>
              <el-button style="width: 120px; height: 36px;">上传图片</el-button>
              <span class="text-999 text-12">请上传剪裁好的圆形照片</span>
            </div>
            <div class="a-avatar">
              <img :src="`${form.logo}`" title="" style="width:90px;height:90px;" />
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="api证书" prop="apiCertUrl">
          <el-upload
            ref="apiCertUrl"
            v-model="form.apiCertUrl"
            class="upload-demo"
            :action="uploadUrl"
            :data="dataObj"
            :limit="1"
            :file-list="fileListApi"
            :on-success="handleSuccessApi"
            :on-remove="handleRemoveApi"
            :on-change="handleChangePicApi"
            :before-upload="beforeUploadFileApi"
            list-type="text"
            drag
          >
          <el-button style="width: 120px; height: 36px;">上传文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="小程序appId" prop="miniAppId">
          <el-input
            ref="miniAppId"
            v-model="form.miniAppId"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="小程序app secret" prop="miniAppSecret">
          <el-input
            ref="miniAppSecret"
            v-model="form.miniAppSecret"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="直播appId" prop="liveAppId">
          <el-input
            ref="liveAppId"
            v-model="form.liveAppId"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="直播app secret" prop="liveAppSecret">
          <el-input
            ref="liveAppSecret"
            v-model="form.liveAppSecret"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="短信开头签名" prop="smsSignName">
          <el-input
            ref="smsSignName"
            v-model="form.smsSignName"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="是否作为子商户" prop="subMchMode">
          <el-switch v-model="form.subMchMode"></el-switch>
        </el-form-item>
        <!-- <el-form-item label="商户appid" prop="mchAppId">
          <el-input
            ref="api3Key"
            v-model="form.mchAppId"
            type="text"
            class="a-input"
          />
        </el-form-item> -->
        <el-form-item label="商户ID" prop="mchId">
          <el-input
            ref="mchId"
            v-model="form.mchId"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="商户密钥" prop="mchKey">
          <el-input
            ref="mchKey"
            v-model="form.mchKey"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="api3Key" prop="api3Key">
          <el-input
            ref="api3Key"
            v-model="form.api3Key"
            type="text"
            class="a-input"
          />
        </el-form-item>
        <el-form-item label="学员是否需要审核" prop="studentInfoVerify">
          <el-switch v-model="form.studentInfoVerify"></el-switch>
        </el-form-item>
        <el-form-item label="大学类型" prop="type">
          <el-radio-group v-model="form.type" @change="onChangeSchoolType">
            <el-radio label="MASTER">主学院</el-radio>
            <el-radio label="SUB">子学院</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type == 'SUB'" label="选择主学院" prop="parentId">
          <el-select v-model="form.parentId" placeholder="请选择">
            <el-option v-for="item in parentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item label="合作模式" prop="cooperationMode">
          <el-select v-model="form.cooperationMode" placeholder="请选择">
            <el-option label="独立小程序" value="STANDALONE"></el-option>
            <el-option label="内部页面" value="INNER_PAGE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务截至日期" prop="serviceStopDate">
          <el-date-picker v-model="form.serviceStopDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="最大用户数" prop="maxUserCount">
          <el-input ref="maxUserCount" v-model="form.maxUserCount" type="text" class="a-input"/>
        </el-form-item>
        <el-form-item label="结业证书url" prop="certOfComplete">
          <el-input ref="certOfComplete" v-model="form.certOfComplete" type="text" class="a-input"/>
        </el-form-item>
        <el-form-item label="banner url" prop="banner">
          <el-input ref="banner" v-model="form.banner" type="text" class="a-input"/>
        </el-form-item>
        <el-button v-if="!edit" type="primary" class="a-button" style="margin-left: 80px;" @click="schoolCreate">创建</el-button>
        <el-button v-else type="primary" class="a-button" style="margin-left: 80px;" @click="schoolUpdate">编辑</el-button>
        <el-button class="a-button" @click="backPage">取消</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import SubTitle from '@/components/SubTitle/index'
import { schoolCreate, getSchool, schoolUpdate, parentSchool } from '@/api/university-manager'
import { getToken } from '@/utils/auth'
import { getEnv } from '@/utils/index'
import { hasPic, validNotEmpty } from '@/utils/validate'
export default {
  name: 'SchoolCreate',
  components: {
    SubTitle
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (!validNotEmpty(value)) {
        callback(new Error('请输入大学名称'))
      } else {
        callback()
      }
    }
    const validateLogo = (rule, value, callback) => {
      if (!hasPic(value)) {
        callback(new Error('请输入大学头像'))
      } else {
        callback()
      }
    }
    return {
      uploadUrl: getEnv()[1] + 'admin/file/upload/temp',
      dataObj: { token: getToken() },
      fileList: [],
      fileListApi: [],
      maxSize: false,
      parentList: [],
      form: {
        name: '',
        logo: '',
        cooperationMode: '',
      },
      rules: {
        name: [
          { required: true, trigger: 'submit', validator: validateName }
        ],
        logo: [
          { required: true, trigger: 'submit', validator: validateLogo }
        ]
      },
      edit: false
    }
  },
  created() {
    if (this.$route.query.schoolId) {
      this.edit = true
      this.getSchool()
    }
  },
  methods: {
    handleSuccess(response) {
      this.form.logo = response.result.url
    },
    // 上传图片前检测
    beforeUploadFile(file) {
      const maxSize = 500
      if (file.type === 'video/mp4') {
        this.$message.error('请上传正确图片格式')
        return false
      }
      if (file.size > maxSize * 1024) {
        this.$message.error('上传图片大小不能超过500KB')
        return false
      }
    },
    handleChangePic(file, fileList) {
      if (fileList.length > 1 && file.size < 500 * 1024) {
        fileList.splice(0, 1)
      }
    },
    handleRemove(file, fileList) {
      this.form.logo = ''
    },

    handleSuccessApi (response) {
      this.form.apiCertUrl = response.result.url
      this.fileListApi = new Array()
      this.fileListApi.push({name:response.result.originalFileName})
    },
    handleRemoveApi (file, fileList) {
      this.form.apiCertUrl = ''
    },
    handleChangePicApi (file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
    },
    beforeUploadFileApi (file) {

    },

    schoolCreate() {
      this.$refs.form.validate(valid => {
        if (valid) {
          schoolCreate({ ...this.form }).then(() => {
            this.$message.success('设置成功')
            this.backPage()
          })
        }
      })
    },

    getSchool() {
      getSchool({ id: this.$route.query.schoolId }).then(res => {
        this.form = res.result;
        this.fileListApi.push({name: "证书"})
      })
    },

    schoolUpdate() {
      schoolUpdate({ ...this.form }).then(res => {
        this.backPage()
        this.$message.success('修改成功')
      })
    },

    onChangeSchoolType (val) {
      if(val == 'SUB' && !this.parentList.length) {
        parentSchool({}).then(res => {
          this.parentList = res.result;
        }).catch(res => {
          this.$message.error(res.errorMsg)
        })
      }
    },

    backPage() {
      this.$router.go(-1)
    }

  }

}
</script>

<style scoped>
  .a-input {
    width: 210px;
    height: 32px;
  }

  .a-avatar {
    width: 90px;
    height: 90px;
    background: #F7F7F7;
    border: 1px solid #E7E7E7;
    border-radius: 50%;
    margin-top: 16px;
  }

  .a-button {
    width: 132px;
    height: 32px;
  }

  /deep/.el-upload-dragger {
    border: none !important;
    text-align: left !important;
    width: auto !important;
    height: auto !important;
  }

  .el-form-item {
    margin-bottom: 32px;
  }

  .upload-demo{
    max-width: 300px;
  }
</style>
