import request from '@/utils/request'

export function getPriceAssignList(params) {
  return request({
    url: '/price/assign_list',
    params,
    type: 'get'
  }, true)
}

export function createPriceAssign(params) {
  return request({
    url: '/price/assign',
    params
  }, true)
}

export function updatePriceAssign(params) {
  return request({
    url: '/price/assign_edit',
    params
  }, true)
}

