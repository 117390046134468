import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/university-manager',
  //   meta: { title: '大学管理', icon: '学士帽' },
  //   children: [{
  //     path: 'university-manager',
  //     name: 'UniversityManager',
  //     component: () => import('@/views/university-manager/index'),
  //     meta: { title: '大学管理', breadcrumb: false }
  //   },
  //   {
  //     path: 'school-create',
  //     name: 'SchoolCreate',
  //     component: () => import('@/views/university-manager/SchoolCreate'),
  //     meta: { title: '新建大学', icon: '直播中' },
  //     hidden: true
  //   }]
  // },

  {
    path: '/admin-login',
    component: () => import('@/views/AdminLogin')
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/university-manager',
    meta: { title: '大学管理', icon: '学士帽', roles: ['ADMIN'] },
    children: [{
      path: '/university-manager',
      name: 'UniversityManager',
      component: () => import('@/views/university-manager/index'),
      meta: { title: '大学管理', breadcrumb: false }
    },
    {
      path: 'school-create',
      name: 'SchoolCreate',
      component: () => import('@/views/university-manager/SchoolCreate'),
      meta: { title: '新建大学' },
      hidden: true
    }]
  },

  {
    path: '/price',
    component: Layout,
    meta: { title: '价格自定义', icon: 'finance', roles: ['ADMIN'] },
    children: [{
      path: '',
      name: 'PriceSetting',
      component: () => import('@/views/price-setting/index'),
      meta: { title: '价格设置' }
    }]
  },

  {
    path: '/schoolprice',
    component: Layout,
    meta: { title: '学校课程价格', icon: 'finance', roles: ['ADMIN'] },
    children: [{
      path: '',
      name: 'SchoolPrice',
      component: () => import('@/views/school-price/index'),
      meta: { title: '学校课程价格' }
    }]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
