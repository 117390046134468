<template><br></template>

<script>
import { getUrlParams } from '@/utils'
export default {
  name: 'AdminLogin',
  created() {
    this.getToken()
  },
  methods: {
    getToken() {
      const adminToken = getUrlParams(window.location.hash).token
      if (adminToken) {
        this.$store.dispatch('user/adminLogin', adminToken).then(() => {
          this.$router.push('/')
        })
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>
