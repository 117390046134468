<template>
  <div class="login-container relative">
    <div class="title">
      <img :src="OSS_API + 'logo_muke.png'" class="title-logo">
    </div>

    <div class="content flex">
      <img :src="OSS_API + 'login_chahua_muke.png'" class="chahua">
      <div style="margin-top:69px;">
        <div class="text-28 text-bold orange text-center" style="line-height:40px;">睦课伴读管理系统</div>
        <div v-show="loginType === 1" class="qrcode-login animation-show text-center">
          <el-image :src="qrCode" class="cu-size-200" style="margin-top: 17px;">
            <div slot="error" class="image-slot">加载中...</div>
          </el-image>
          <div class="margin-top-20">
            <div class="flex align-center">
              <img :src="OSS_API + 'scan_muke.png'" class="icon-scan">
              <div class="text-666 text-14 margin-left-10">
                打开手机
                <span class="orange">微信</span> 扫一扫登录
              </div>
            </div>
            <div class="orange clickable text-14 margin-top-10" @click="changeLoginType">或使用账号密码登录</div>
          </div>
        </div>
        <div v-show="loginType === 2" class="animation-show margin-top-50">
          <div>
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginRules"
              class="login-form animation-show"
              auto-complete="on"
              label-position="left"
            >
              <el-form-item prop="username">
                <span class="text-16 text-555">登录账号</span>
                <el-input
                  ref="username"
                  v-model="loginForm.username"
                  placeholder="账号"
                  name="username"
                  type="text"
                  tabindex="1"
                  autocomplete="on"
                />
              </el-form-item>
              <el-form-item prop="password">
                <span class="text-16 text-555">登陆密码</span>
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model="loginForm.password"
                  :type="passwordType"
                  placeholder="密码"
                  name="password"
                  tabindex="2"
                  autocomplete="on"
                  @keyup.enter.native="loginByAccount"
                />
                <span class="show-pwd" @click="showPwd">
                  <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                </span>
              </el-form-item>
              <el-button :loading="loading" type="primary" class="margin-top-20" style="width: 100%; margin-bottom: 25px;" @click.native.prevent="loginByAccount">登录</el-button>
              <div class="text-14 flex orange clickable" style="justify-content:center"><div @click="changeLoginType">微信扫码登录</div></div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validNotEmpty, validPassword } from '@/utils/validate'
import { mapGetters } from 'vuex'
import { getUser, getQrCode, loginByAccount } from '@/api/login'
import { setToken } from '@/utils/auth'

export default {
  name: 'Login',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!validNotEmpty(value)) {
        callback(new Error('请输入账号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (!validPassword(value)) {
        callback(new Error('请输入至少6位密码'))
      } else {
        callback()
      }
    }
    return {
      qrCode: '',
      loginForm: {},
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ]
      },
      loginType: 1,
      passwordType: 'password',
      loading: false,
      redirect: undefined,
      tempToken: undefined,
      count: 0,
      timer: null
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ])
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    },
    loginType: {
      handler: function(type) {
        if (type === 1) {
          this.count = 0
          // this.timer = setInterval(this.getUser(), 3000)
        } else {
          clearTimeout(this.timer)
        }
      }
    }
  },
  created() {
    this.getQrCode()
  },
  methods: {
    routePush() {
      console.log('跳转')
      this.$router.push({ path: '/' || this.redirect })
    },

    getQrCode() {
      getQrCode().then(res => {
        this.qrCode = 'https://course.muketang.com/' + res.result.image
        if (res.result.token) {
          this.tempToken = res.result.token
          this.$store.commit('user/SET_TOKEN', this.tempToken)
          setToken(this.tempToken)
          this.getUser()
        }
      })
    },

    getUser() {
      getUser({ token: this.tempToken })
        .then(res => {
          this.routePush()
        })
        .catch(err => {
          if (err.code === 401) {
            this.timer = setTimeout(() => {
              this.getUser()
            }, 3000)
          }
        })
    },

    loginByAccount() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          // loginByAccount({ token: this.tempToken, account: this.loginForm.username.trim(), password: this.loginForm.password.trim() })
          //   .then(res => {
          //     this.routePush()
          //     this.loading = false
          //   }).catch(err => {
          //     this.loading = false
          //     console.log(err)
          //   })
          this.$store.dispatch('user/login', this.loginForm).then(() => {
            this.routePush()
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    changeLoginType() {
      this.loginType = 3 - this.loginType
      // if (this.loginType === 1) {
      //   this.getQrCode()
      // } else {
      //   clearTimeout(this.timer)
      // }
    },

    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$dark_gray:#889aa4;
.login-container {
  width: 100vw;
  height: 100vh;
  line-height: 1;
  background-image: url("http://txm.oss-cn-beijing.aliyuncs.com/TXM_static_material_img_login_bg_muke.png");
  background-size: cover;
  background-position: center;
  .title-logo {
    width: 140px;
    height: 39px;
    margin: 40px 0 0 60px;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 790px;
    height: 480px;
    background: #ffffff;
    border-radius: 16px;
    .chahua {
      width: 290px;
      height: 292px;
      margin: 94px 135px 0 53px;
    }
    .qrcode-login {
      width: 230px;
      height: 230px;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      margin-top: 26px;
      .cu-size-200 {
        height: 200px;
        width: 197px;
      }
      .icon-scan {
        width: 26px;
        height: 26px;
        margin-left: 14px;
      }
    }
    /* reset element-ui css */
    .login-form {
      .el-input {
        display: inline-block;
        height: 47px;
        width: 150px; // 80%
        background: white;
        /deep/ input {
          background: white;
          border: 0;
          -webkit-appearance: none;
          border-radius: 0;
          padding: 12px 5px 12px 15px;
          color: #555555;
          height: 40px;
          // caret-color: $cursor;

          &:-webkit-autofill {
            box-shadow: 0 0 0 1000px white inset !important;
            -webkit-text-fill-color: #555555 !important;
          }
        }
      }

      .el-form-item {
        display: flex;
        border-bottom: 1px solid #dedede;
        background: white;
        border-radius: 5px;
        color: #454545;
      }

      .el-form-item__error {
        width: 99%;
        text-align: right;
      }

      .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: $dark_gray;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>
