var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-container" },
    [
      _c("sub-title", { attrs: { title: "大学列表" } }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "margin-top-30",
              staticStyle: { width: "104px", height: "32px" },
              attrs: { type: "primary" },
              on: { click: _vm.goCreate }
            },
            [_vm._v("新建大学")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              staticClass: "margin-top-20",
              staticStyle: { width: "1624px" },
              attrs: { data: _vm.list, border: "", "highlight-current-row": "" }
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "id", label: "序号" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "大学名称" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "creator.nickName", label: "创建者" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "time", label: "创建时间", width: "200" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "courseCount", label: "课程数" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "adminPassword", label: "管理员密码" }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  width: "200",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.goEditSchool(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("修改")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.schoolDel(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0 && !_vm.listLoading,
                expression: "total>0 && !listLoading"
              }
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              pagesize: _vm.listQuery.count
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:pagesize": function($event) {
                return _vm.$set(_vm.listQuery, "count", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }