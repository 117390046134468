import request from '@/utils/request'

export function getQrCode() {
  return request({
    url: '/qrcode'
  })
}

export function getUser(data) {
  return request({
    url: '/user',
    params: data
  }, true)
}

export function loginByAccount(data) {
  return request({
    url: '/login_by_account',
    params: data
  })
}
