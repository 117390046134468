import request from '@/utils/request'

// 创建学校
export function schoolCreate(data) {
  return request({
    url: '/admin/school/create',
    data
  })
}

export function schoolList(params) {
  return request({
    url: '/school_list',
    params
  })
}

export function getSchool(params) {
  return request({
    url: '/school',
    params
  })
}

export function schoolUpdate(data) {
  return request({
    url: '/admin/school/update',
    data
  })
}

export function schoolDel(params) {
  return request({
    url: '/admin/school/del',
    params
  })
}

export function parentSchool(params) {
  return request({
    url: '/centeral_school_list',
    params,
    type: 'get'
  })
}