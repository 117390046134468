<template>
  <div class="a-container">
    <sub-title title="大学列表" />
    <div>
      <el-button type="primary" class="margin-top-30" style="width: 104px; height: 32px;" @click="goCreate">新建大学</el-button>
      <el-table
        v-loading="listLoading"
        :data="list"
        border
        class="margin-top-20"
        highlight-current-row
        style="width: 1624px;"
      >
        <el-table-column
          fixed
          prop="id"
          label="序号"
        />
        <el-table-column
          prop="name"
          label="大学名称"
        />
        <el-table-column
          prop="creator.nickName"
          label="创建者"
        />
        <el-table-column
          prop="time"
          label="创建时间"
          width="200"
        />
        <el-table-column
          prop="courseCount"
          label="课程数"
        />
        <el-table-column
          prop="adminPassword"
          label="管理员密码"
        />
        <el-table-column
          fixed="right"
          label="操作"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goEditSchool(scope.row.id)">修改</el-button>
            <el-button type="text" size="small" @click="schoolDel(scope.row.id)">删除</el-button>
            <!-- <el-button type="text" size="small" @click="goCourseManager(scope.row.id, scope.row.name)">课程管理</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="listQuery.page" :pagesize.sync="listQuery.count" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import SubTitle from '@/components/SubTitle/index'
import Pagination from '@/components/Pagination'
import { schoolList, schoolDel } from '@/api/university-manager'
export default {
  name: 'UniversityManager',
  components: {
    SubTitle,
    Pagination
  },
  data() {
    return {
      listQuery: {
        page: 0,
        count: 10
      },
      total: 0,
      listLoading: true,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      schoolList({ ...this.listQuery }).then(res => {
        this.listLoading = false
        this.list = res.result.items
        this.total = res.result.total
      })
    },

    goCreate() {
      this.$router.push({ name: 'SchoolCreate' })
    },

    goCourseManager(schoolId, schoolName) {
      this.$router.push({
        name: 'TeacherManager',
        query: {
          schoolId: schoolId,
          schoolName: schoolName
        }
      })
    },

    goEditSchool(schoolId) {
      this.$router.push({
        name: 'SchoolCreate',
        query: {
          schoolId: schoolId
        }
      })
    },

    schoolDel(schoolId) {
      schoolDel({ id: schoolId }).then(res => {
        this.$message.success('删除成功')
      })
    }
  }

}
</script>

<style>

</style>
