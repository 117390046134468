<template>
  <div class="price_box">
    <el-button type="primary" style="width: 104px; height: 32px;" @click="tapCreate">新建</el-button>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        prop="name"
        label="价格名称"
      />
      <el-table-column
        prop="flag"
        label="字段"
      />
      <el-table-column
          fixed="right"
          label="操作"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="tapEditItem(scope.row)">修改</el-button>
            <!-- <el-button type="text" size="small" @click="tapDelItem(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" />

    <!-- 创建，编辑 -->
    <el-dialog :title="createParam.id ? '编辑' : '新建'" :visible.sync="showCreate" width="30%">
      <el-form :model="createParam" :rules="rules" ref="form" label-width="50px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="createParam.name"></el-input>
        </el-form-item>
        <el-form-item label="字段" prop="flag">
          <el-select v-model="createParam.flag" placeholder="请选择" class="width-100">
            <el-option v-for="item in flagsList" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreate = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getPriceList, createPrice, updatePrice, getPriceFlags } from '@/api/price-setting'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        count: 10
      },
      total: 0,
      dataList: [],
      listLoading: true,
      showCreate: false,
      flagsList: [],
      createParam: {
        id: '',
        name: '',
        flag: '',
      },
      rules: {
        name: [
          { required: true, trigger: 'submit', message: '请输入名称' }
        ],
        flag: [
          { required: true, trigger: 'submit', message: '请选择字段' }
        ]
      }
    }
  },
  mounted () {
    this.getData()
    this.getOptions()
  },
  methods: {
    getData () {
      this.listLoading = true;
      getPriceList({ ...this.param }).then(res => {
        this.listLoading = false;
        this.dataList = res.result.items;
        this.total = res.result.total
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    getOptions () {
      getPriceFlags().then(res => {
        this.flagsList = res.result
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    tapCreate () {
      for(let k in this.createParam) {
        this.createParam[k] = '';
      }
      this.showCreate = true;
    },
    tapConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if(this.createParam.id) {
            updatePrice({ ...this.createParam }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          } else {
            createPrice({ ...this.createParam }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          }
        }
      })
    },
    tapDelItem (row) {
      this.$confirm('确定要作废此条消息吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editNotice({ id: row.id, title: row.title, content: row.content, enabled: false }).then(res => {
          this.$message.success('操作成功')
          this.getData()
        })
        // delNotice({ id: row.id }).then(res => {
        //   this.$message.success('删除成功')
        //   this.getData()
        // })
      }).catch(() => { });
    },
    tapEditItem (item) {
      this.createParam.id = item.id;
      this.createParam.name = item.name;
      this.createParam.flag = item.flag;
      this.showCreate = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.price_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}

</style>