import request from '@/utils/request'

export function getPriceList(params) {
  return request({
    url: '/price/list',
    params,
    type: 'get'
  }, true)
}

export function createPrice(params) {
  return request({
    url: '/price/add',
    params
  }, true)
}

export function updatePrice(params) {
  return request({
    url: '/price/edit',
    params
  }, true)
}

export function getPriceFlags(params) {
  return request({
    url: '/price/flags',
    params,
    type: 'get'
  }, true)
}
