var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "price_box" },
    [
      _c(
        "div",
        { staticClass: "flex align-center flex-wrap" },
        [
          _c(
            "div",
            { staticClass: "flex-center margin-right-30 margin-bottom-20" },
            [
              _vm._v("学校：\n      "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.param.schoolId,
                    callback: function($$v) {
                      _vm.$set(_vm.param, "schoolId", $$v)
                    },
                    expression: "param.schoolId"
                  }
                },
                _vm._l(_vm.schoolList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              on: { click: _vm.getData }
            },
            [_vm._v("查看")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "margin-right-20 margin-bottom-20",
              attrs: { type: "primary" },
              on: { click: _vm.tapCreate }
            },
            [_vm._v("新建")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "margin-top-20",
          staticStyle: { width: "1624px" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "school.name", label: "学校" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "price.name", label: "价格设置" }
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "name", label: "价格名称" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "showOrder", label: "后端显示顺序" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "frontPrice", label: "小程序前端显示价格" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.frontPrice ? "是" : "否"))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "200",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.tapEditItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0 && !_vm.listLoading,
            expression: "total>0 && !listLoading"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.param.page,
          pagesize: _vm.param.count
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.param, "page", $event)
          },
          "update:pagesize": function($event) {
            return _vm.$set(_vm.param, "count", $event)
          },
          pagination: _vm.getData
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.createParam.id ? "编辑" : "新建",
            visible: _vm.showCreate,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.showCreate = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.createParam,
                rules: _vm.rules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择学校", prop: "schoolId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-100",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.createParam.schoolId,
                        callback: function($$v) {
                          _vm.$set(_vm.createParam, "schoolId", $$v)
                        },
                        expression: "createParam.schoolId"
                      }
                    },
                    _vm._l(_vm.schoolList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "选择价格设置", prop: "priceId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-100",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.createParam.priceId,
                        callback: function($$v) {
                          _vm.$set(_vm.createParam, "priceId", $$v)
                        },
                        expression: "createParam.priceId"
                      }
                    },
                    _vm._l(_vm.priceList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "价格名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.createParam.name,
                      callback: function($$v) {
                        _vm.$set(_vm.createParam, "name", $$v)
                      },
                      expression: "createParam.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "后端显示顺序", prop: "order" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.createParam.order,
                      callback: function($$v) {
                        _vm.$set(_vm.createParam, "order", $$v)
                      },
                      expression: "createParam.order"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "小程序前端显示价格", prop: "frontPrice" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.createParam.frontPrice,
                      callback: function($$v) {
                        _vm.$set(_vm.createParam, "frontPrice", $$v)
                      },
                      expression: "createParam.frontPrice"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showCreate = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.tapConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }