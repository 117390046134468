var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "price_box" },
    [
      _c(
        "el-button",
        {
          staticStyle: { width: "104px", height: "32px" },
          attrs: { type: "primary" },
          on: { click: _vm.tapCreate }
        },
        [_vm._v("新建")]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticClass: "margin-top-20",
          staticStyle: { width: "1624px" },
          attrs: { data: _vm.dataList, border: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "name", label: "价格名称" } }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { prop: "flag", label: "字段" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              width: "200",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.tapEditItem(scope.row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0 && !_vm.listLoading,
            expression: "total>0 && !listLoading"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.param.page,
          pagesize: _vm.param.count
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.param, "page", $event)
          },
          "update:pagesize": function($event) {
            return _vm.$set(_vm.param, "count", $event)
          },
          pagination: _vm.getData
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.createParam.id ? "编辑" : "新建",
            visible: _vm.showCreate,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showCreate = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.createParam,
                rules: _vm.rules,
                "label-width": "50px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.createParam.name,
                      callback: function($$v) {
                        _vm.$set(_vm.createParam, "name", $$v)
                      },
                      expression: "createParam.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "字段", prop: "flag" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-100",
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.createParam.flag,
                        callback: function($$v) {
                          _vm.$set(_vm.createParam, "flag", $$v)
                        },
                        expression: "createParam.flag"
                      }
                    },
                    _vm._l(_vm.flagsList, function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showCreate = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.tapConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }