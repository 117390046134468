var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a-container" },
    [
      _c("sub-title", { attrs: { title: "新建大学" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "margin-top-30" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "大学名称", prop: "name" } },
                [
                  _c("el-input", {
                    ref: "name",
                    staticClass: "a-input",
                    attrs: {
                      type: "text",
                      maxlength: "10",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "大学头像", prop: "logo" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "logo",
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        data: _vm.dataObj,
                        "on-success": _vm.handleSuccess,
                        "on-remove": _vm.handleRemove,
                        "on-change": _vm.handleChangePic,
                        "file-list": _vm.fileList,
                        "before-upload": _vm.beforeUploadFile,
                        "show-file-list": false,
                        "list-type": "picture",
                        drag: ""
                      },
                      model: {
                        value: _vm.form.logo,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "logo", $$v)
                        },
                        expression: "form.logo"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            { staticStyle: { width: "120px", height: "36px" } },
                            [_vm._v("上传图片")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-999 text-12" }, [
                            _vm._v("请上传剪裁好的圆形照片")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "a-avatar" }, [
                        _c("img", {
                          staticStyle: { width: "90px", height: "90px" },
                          attrs: { src: "" + _vm.form.logo, title: "" }
                        })
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "api证书", prop: "apiCertUrl" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "apiCertUrl",
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        data: _vm.dataObj,
                        limit: 1,
                        "file-list": _vm.fileListApi,
                        "on-success": _vm.handleSuccessApi,
                        "on-remove": _vm.handleRemoveApi,
                        "on-change": _vm.handleChangePicApi,
                        "before-upload": _vm.beforeUploadFileApi,
                        "list-type": "text",
                        drag: ""
                      },
                      model: {
                        value: _vm.form.apiCertUrl,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "apiCertUrl", $$v)
                        },
                        expression: "form.apiCertUrl"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { staticStyle: { width: "120px", height: "36px" } },
                        [_vm._v("上传文件")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "小程序appId", prop: "miniAppId" } },
                [
                  _c("el-input", {
                    ref: "miniAppId",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.miniAppId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "miniAppId", $$v)
                      },
                      expression: "form.miniAppId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "小程序app secret", prop: "miniAppSecret" } },
                [
                  _c("el-input", {
                    ref: "miniAppSecret",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.miniAppSecret,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "miniAppSecret", $$v)
                      },
                      expression: "form.miniAppSecret"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播appId", prop: "liveAppId" } },
                [
                  _c("el-input", {
                    ref: "liveAppId",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.liveAppId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "liveAppId", $$v)
                      },
                      expression: "form.liveAppId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "直播app secret", prop: "liveAppSecret" } },
                [
                  _c("el-input", {
                    ref: "liveAppSecret",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.liveAppSecret,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "liveAppSecret", $$v)
                      },
                      expression: "form.liveAppSecret"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "短信开头签名", prop: "smsSignName" } },
                [
                  _c("el-input", {
                    ref: "smsSignName",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.smsSignName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "smsSignName", $$v)
                      },
                      expression: "form.smsSignName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否作为子商户", prop: "subMchMode" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.subMchMode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "subMchMode", $$v)
                      },
                      expression: "form.subMchMode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商户ID", prop: "mchId" } },
                [
                  _c("el-input", {
                    ref: "mchId",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.mchId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mchId", $$v)
                      },
                      expression: "form.mchId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商户密钥", prop: "mchKey" } },
                [
                  _c("el-input", {
                    ref: "mchKey",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.mchKey,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mchKey", $$v)
                      },
                      expression: "form.mchKey"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "api3Key", prop: "api3Key" } },
                [
                  _c("el-input", {
                    ref: "api3Key",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.api3Key,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "api3Key", $$v)
                      },
                      expression: "form.api3Key"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "学员是否需要审核",
                    prop: "studentInfoVerify"
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.studentInfoVerify,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "studentInfoVerify", $$v)
                      },
                      expression: "form.studentInfoVerify"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "大学类型", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.onChangeSchoolType },
                      model: {
                        value: _vm.form.type,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "MASTER" } }, [
                        _vm._v("主学院")
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "SUB" } }, [
                        _vm._v("子学院")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.type == "SUB"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择主学院", prop: "parentId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.parentId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "parentId", $$v)
                            },
                            expression: "form.parentId"
                          }
                        },
                        _vm._l(_vm.parentList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "合作模式", prop: "cooperationMode" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.cooperationMode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "cooperationMode", $$v)
                        },
                        expression: "form.cooperationMode"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "独立小程序", value: "STANDALONE" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "内部页面", value: "INNER_PAGE" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "服务截至日期", prop: "serviceStopDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      "value-format": "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.form.serviceStopDate,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "serviceStopDate", $$v)
                      },
                      expression: "form.serviceStopDate"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最大用户数", prop: "maxUserCount" } },
                [
                  _c("el-input", {
                    ref: "maxUserCount",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.maxUserCount,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "maxUserCount", $$v)
                      },
                      expression: "form.maxUserCount"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "结业证书url", prop: "certOfComplete" } },
                [
                  _c("el-input", {
                    ref: "certOfComplete",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.certOfComplete,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "certOfComplete", $$v)
                      },
                      expression: "form.certOfComplete"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "banner url", prop: "banner" } },
                [
                  _c("el-input", {
                    ref: "banner",
                    staticClass: "a-input",
                    attrs: { type: "text" },
                    model: {
                      value: _vm.form.banner,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "banner", $$v)
                      },
                      expression: "form.banner"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.edit
                ? _c(
                    "el-button",
                    {
                      staticClass: "a-button",
                      staticStyle: { "margin-left": "80px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.schoolCreate }
                    },
                    [_vm._v("创建")]
                  )
                : _c(
                    "el-button",
                    {
                      staticClass: "a-button",
                      staticStyle: { "margin-left": "80px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.schoolUpdate }
                    },
                    [_vm._v("编辑")]
                  ),
              _vm._v(" "),
              _c(
                "el-button",
                { staticClass: "a-button", on: { click: _vm.backPage } },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }