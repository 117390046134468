<template>
  <div class="price_box">
    <div class="flex align-center flex-wrap">
      <div class="flex-center margin-right-30 margin-bottom-20">学校：
        <el-select v-model="param.schoolId" placeholder="请选择" style="width: 150px;" clearable>
          <el-option
            v-for="item in schoolList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <el-button class="margin-right-20 margin-bottom-20" @click="getData">查看</el-button>
      <el-button type="primary" class="margin-right-20 margin-bottom-20"  @click="tapCreate">新建</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="dataList"
      border
      class="margin-top-20"
      style="width: 1624px;"
    >
      <el-table-column
        prop="school.name"
        label="学校"
      />
      <el-table-column
        prop="price.name"
        label="价格设置"
      />
      <el-table-column
        prop="name"
        label="价格名称"
      />
      <el-table-column
        prop="showOrder"
        label="后端显示顺序"
      />
      <el-table-column
        prop="frontPrice"
        label="小程序前端显示价格"
      >
      <template slot-scope="scope">
        <span>{{ scope.row.frontPrice ? '是' : '否' }}</span>
      </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="tapEditItem(scope.row)">修改</el-button>
            <!-- <el-button type="text" size="small" @click="tapDelItem(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
    </el-table>
    <pagination v-show="total>0 && !listLoading" :total="total" :page.sync="param.page" :pagesize.sync="param.count" @pagination="getData" />

    <!-- 创建，编辑 -->
    <el-dialog :title="createParam.id ? '编辑' : '新建'" :visible.sync="showCreate" width="600px">
      <el-form :model="createParam" :rules="rules" ref="form" label-width="150px">
        <el-form-item label="选择学校" prop="schoolId">
          <el-select v-model="createParam.schoolId" placeholder="请选择" class="width-100">
            <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="选择价格设置" prop="priceId">
          <el-select v-model="createParam.priceId" placeholder="请选择" class="width-100">
            <el-option v-for="item in priceList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="价格名称" prop="name">
          <el-input v-model="createParam.name"></el-input>
        </el-form-item>
        <el-form-item label="后端显示顺序" prop="order">
          <el-input v-model="createParam.order"></el-input>
        </el-form-item>
        <el-form-item label="小程序前端显示价格" prop="frontPrice">
          <el-switch v-model="createParam.frontPrice"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="showCreate = false">取 消</el-button>
        <el-button type="primary" @click="tapConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { schoolList } from '@/api/university-manager'
import { getPriceList } from '@/api/price-setting'
import { getPriceAssignList, createPriceAssign, updatePriceAssign } from  '@/api/school-price'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      param: {
        page: 0,
        count: 10,
        schoolId: '',
      },
      total: 0,
      dataList: [],
      listLoading: true,
      showCreate: false,
      flagsList: [],
      schoolList: [],
      priceList: [],
      createParam: {
        id: '',
        priceId: '',
        schoolId: '',
        name: '',
        order: '',
        frontPrice: true,
      },
      rules: {
        priceId: [
          { required: true, trigger: 'submit', message: '请输入价格设置' }
        ],
        schoolId: [
          { required: true, trigger: 'submit', message: '请选择学校' }
        ]
      }
    }
  },
  mounted () {
    this.getData()
    this.getOptions()
  },
  methods: {
    getData () {
      this.listLoading = true;
      getPriceAssignList({ ...this.param }).then(res => {
        this.listLoading = false;
        this.dataList = res.result.items;
        this.total = res.result.total
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    getOptions () {
      getPriceList({ page: 0, count: 1000 }).then(res => {
        this.priceList = res.result.items
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
      schoolList({ page: 0, count: 1000 }).then(res => {
        this.schoolList = res.result.items
      }).catch(err => {
        this.$message.error(err.errorMsg)
      })
    },
    tapCreate () {
      for(let k in this.createParam) {
        this.createParam[k] = '';
      }
      this.createParam.frontPrice = true
      this.showCreate = true;
    },
    tapConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if(this.createParam.id) {
            updatePriceAssign({ ...this.createParam }).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          } else {
            let params = { ...this.createParam }
            delete params.id;
            createPriceAssign(params).then(res => {
              this.$message.success('操作成功')
              this.showCreate = false;
              this.getData()
            }).catch(err => {
              this.$message.error(err.errorMsg)
            })
          }
        }
      })
    },
    tapDelItem (row) {
      this.$confirm('确定要作废此条消息吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editNotice({ id: row.id, title: row.title, content: row.content, enabled: false }).then(res => {
          this.$message.success('操作成功')
          this.getData()
        }).catch(err => {
          this.$message.error(err.errorMsg)
        })
      }).catch(() => { });
    },
    tapEditItem (item) {
      this.createParam.id = item.id;
      this.createParam.priceId = item.price.id;
      this.createParam.schoolId = item.school.id;
      this.createParam.name = item.name;
      this.createParam.order = item.showOrder;
      this.createParam.frontPrice = item.frontPrice;
      this.showCreate = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.price_box{
  margin: 24px;
  line-height: 1;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}

</style>